















import { Component, Prop, Vue } from 'vue-property-decorator';
import { CaseView } from '@/models/case-maintenance';
import config from '@/config';

@Component
export default class CaseDetailStringComponent extends Vue {
  @Prop({ required: true }) case!: CaseView;
  @Prop({ required: true }) detail!: typeof config.displayCaseDetails[0];

  get disabled() {
    return !this.href.value;
  }

  get href() {
    const details = this.case['case-details'] && this.case['case-details']
      .filter(x => x.key === this.detail.key)
    
    if (details.length === 0) return { 
      value: "",
      target: ""
    };
    
    return { 
      value: details[0].value,
      target: this.detail.target
    }
  }

}
