





























import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { TableHeader } from '@/components/layout/models/table.d';
import { User } from '@/models/case-maintenance.d';
import AssignmentControl from '@/components/assignment-control.vue';

@Component({
  components: {
    AssignmentControl
  },
})
export default class MultiAssignmentTable extends Vue {
  @Prop({ default: () => [] }) items!: [];
  @Prop({ default: () => [] }) users!: User[];
  @Prop({ default: () => [] }) selectedItems!: any[];

  headers: TableHeader[] = [];

  selected: any[] = [];

  async mounted(): Promise<void> {
    this.headers = [{
      text: 'Case Ref',
      value: 'case-id',
      sortable: false,
      divider: true,
      width: '1px',
    }, {
      text: 'Assigned To',
      value: 'assigned-to',
      sortable: false,
      divider: true,
      width: '120px'
    }];
  }

  lookUpUser(userId: string): string {
    let result = "";
    if (this.users.length > 0)
    {
      if (userId) {
        const foundUser = this.users.find(e => e.id == userId);
        if (foundUser)
        {
          result = foundUser.name;
        }
      }
      else {
        result = "<Unassigned>";
      }
    }
    return result;
  }
}
