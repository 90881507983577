


















































































































































import { Component, Vue, Prop, PropSync, Inject, Watch } from "vue-property-decorator";

import { TableHeader } from "@/components/layout/models/table.d";
import StatusControl from "@/components/status-control.vue";
import AssignmentControl from "@/components/assignment-control.vue";
import CaseFilter from "@/components/case-filter/case-filter.vue";
import AssignmentFilter from "@/components/case-list/assignment-filter.vue";
import CaseDetail from "@/components/case-list/case-detail.vue";
import CaseTableExpand from "@/components/case-list/case-table-expand.vue";
import CloseCaseDialog from "@/components/case/close-case-dialog.vue";
import StatusConfigDialog from "@/components/configuration/status-config-dialog.vue";
import { CaseView } from "@/models/case-maintenance";
import { CaseStatus } from "@/models/case-status";
import { User } from "@/models/case-maintenance.d";
import { Filter, FilterField } from "@/models/filters";
import delay from "@/helpers/delay";
import ConfigurationService, { AllStatusesConfig } from "@/services/configuration-service";

import { getModule } from "vuex-module-decorators";
import config from "@/config";
import UserService from "@/services/user-service";
import CaseService from "@/services/case-service";
import StatusService, { MetadataValuesModel } from "@/services/status-service";

import { AxiosError } from "node_modules/axios";
import SessionState from "@/store/modules/session-state-module";

const sessionState = getModule(SessionState);

@Component({
  components: {
    StatusControl,
    AssignmentControl,
    CaseFilter,
    AssignmentFilter,
    CloseCaseDialog,
    StatusConfigDialog,
    CaseDetail,
    CaseTableExpand
  },
  inheritAttrs: false
})
export default class CaseTable extends Vue {
  @Inject() CaseService!: CaseService;
  @Inject() UserService!: UserService;
  @Inject() StatusService!: StatusService;
  @Inject() ConfigurationService!: ConfigurationService;

  @Prop({ default: 0 }) serverItemsLength!: number;
  @PropSync("itemsPerPage", { default: () => config.defaultPageSize })
  syncedItemsPerPage!: number;
  @PropSync("page", { default: 1 }) syncedPage!: number;
  @PropSync("filters", { required: true }) syncedFilters!: Filter[];
  @PropSync("assignmentFilter", { required: true })
  syncedAssignmentFilter!: string;
  @Prop({ default: () => [] }) items!: CaseView[];

  @Prop({ default: false }) apiFault!: boolean;

  @Prop({ default: false }) loading!: boolean;

  @Prop({ required: true }) filterFields!: FilterField[];

  headers: TableHeader[] = [];

  detailsColumns: typeof config.displayCaseDetails = [];

  statusUpdateState: { [key: string]: string } = {};
  @PropSync("statusErrors", { required: true }) syncedStatusErrors!: {
    [key: string]: string;
  };

  assignmentUpdateState: { [key: string]: string } = {};
  @PropSync("assignmentErrors", { required: true }) syncedAssignmentErrors!: {
    [key: string]: string;
  };

  users: User[] = [];

  showStatusConfigDialog = false;
  newCaseStatusId = -1;
  closeReason = "";
  closeNote = "";
  updatedCase?: CaseView;

  statusMetadataConfig: AllStatusesConfig = { statuses: [] };

  dataTableKey = 0;
  redrawTable() {
    this.dataTableKey++;
  }

  get numberOfPages() {
    if (this.syncedItemsPerPage === 0) return 1;
    return Math.max(1, Math.ceil(this.serverItemsLength / this.syncedItemsPerPage));
  }

  rowNumber(index: number) {
    if (this.serverItemsLength == 0) return 0;

    return (this.syncedPage - 1) * this.syncedItemsPerPage + index + 1;
  }

  openCase(item: CaseView) {
    const selection = document.getSelection()?.toString();
    if (selection === "") {
      sessionState.setCaseListQuery(this.$route.query);

      sessionState.setCases(this.items);

      this.$router.push({ name: "case", params: { id: item["case-id"] } }).catch(() => {
        return true;
      });
    }
  }

  onDropdownStatusChange(item: CaseView, newStatus: CaseStatus) {
    Vue.set(this.statusUpdateState, item["case-id"], "loading");

    this.newCaseStatusId = newStatus;
    this.updatedCase = item;

    const metadataConfigForSelectedStatus = this.statusMetadataConfig.statuses.find(
      x => x.id === this.newCaseStatusId
    );

    if (!metadataConfigForSelectedStatus) return;

    if (
      metadataConfigForSelectedStatus.metadata.length != 0 ||
      metadataConfigForSelectedStatus.mandatoryNotes
    ) {
      this.showStatusConfigDialog = true;
    } else {
      const emptyMetadata: MetadataValuesModel = { metadata: [], note: "" };
      this.updateStatus(item, newStatus, emptyMetadata);
    }
  }

  getConfigTemplate(caseStatus: CaseStatus) {
    const selectedConfig = this.statusMetadataConfig.statuses.filter(x => x.id === caseStatus)[0];
    return selectedConfig;
  }

  async updateStatus(item: CaseView, newStatus: CaseStatus, metadataValues: MetadataValuesModel) {
    try {
      await this.StatusService.setCaseStatus(item, newStatus, metadataValues, {
        willHandle: () => true
      });
      item["status"] = newStatus;
      Vue.set(this.statusUpdateState, item["case-id"], "success");
      await delay(2000);
      Vue.set(this.statusUpdateState, item["case-id"], "default");
    } catch (thrown) {
      Vue.set(this.statusUpdateState, item["case-id"], "error");

      if (typeof thrown === "string") {
        Vue.set(this.syncedStatusErrors, item["case-id"], thrown);
        return;
      }

      const response = (thrown as AxiosError).response;
      const error = response?.data.errors[0];
      if (response?.status === 409) {
        Vue.set(this.syncedStatusErrors, item["case-id"], "The case status has been modified.");
      } else if (error) {
        const message = error.message;
        Vue.set(this.syncedStatusErrors, item["case-id"], message);
      }
    } finally {
      this.$emit("refresh", { case: item });
    }
  }

  onDialogUpdate(model: MetadataValuesModel) {
    if (this.updatedCase) {
      this.updateStatus(this.updatedCase, this.newCaseStatusId, model);
      this.updatedCase = undefined;
    }
    this.showStatusConfigDialog = false;
  }

  onDialogCancel() {
    if (this.updatedCase) {
      Vue.set(this.statusUpdateState, this.updatedCase["case-id"], "default");
      this.updatedCase = undefined;
      this.redrawTable();
    }
    this.showStatusConfigDialog = false;
  }

  @Watch("statusErrors")
  statusErrorsChanged() {
    if (Object.keys(this.syncedStatusErrors).length == 0) {
      this.statusUpdateState = {};
    }
  }

  statusError(item: CaseView) {
    return this.syncedStatusErrors && this.syncedStatusErrors[item["case-id"]];
  }

  statusUpdate(item: CaseView) {
    return this.statusUpdateState && this.statusUpdateState[item["case-id"]];
  }

  async updateAssignment(item: CaseView, userId: string | null) {
    Vue.set(this.assignmentUpdateState, item["case-id"], "loading");
    Vue.set(this.syncedAssignmentErrors, item["case-id"], null);

    try {
      if (userId == null) {
        await this.UserService.unassignCase(item, { willHandle: () => true });
      } else {
        await this.UserService.assignCaseToUser(item, userId, {
          willHandle: () => true
        });
      }
      item["assigned-to"] = userId;
      Vue.set(this.assignmentUpdateState, item["case-id"], "success");
      await delay(2000);
      Vue.set(this.assignmentUpdateState, item["case-id"], "default");
    } catch (thrown) {
      Vue.set(this.assignmentUpdateState, item["case-id"], "error");

      if (typeof thrown === "string") {
        Vue.set(this.syncedAssignmentErrors, item["case-id"], thrown);
        return;
      }

      const response = (thrown as AxiosError).response;
      const error = response?.data.errors[0];
      if (response?.status === 409) {
        Vue.set(this.syncedAssignmentErrors, item["case-id"], "The assignee has been modified.");
      } else {
        const message = error.message;
        Vue.set(this.syncedAssignmentErrors, item["case-id"], message);
      }
    } finally {
      this.$emit("refresh", { case: item });
    }
  }

  @Watch("assignmentErrors")
  assignmentErrorsChanged() {
    if (Object.keys(this.syncedAssignmentErrors).length == 0) {
      this.assignmentUpdateState = {};
    }
  }

  assignmentErrorFor(item: CaseView) {
    return this.syncedAssignmentErrors && this.syncedAssignmentErrors[item["case-id"]];
  }

  assignmentUpdateStateFor(item: CaseView) {
    return this.assignmentUpdateState && this.assignmentUpdateState[item["case-id"]];
  }

  getRowClass(item: CaseView) {
    switch (item.status) {
      case CaseStatus.New:
        return "case-row-new";
      case CaseStatus.InProgress:
        return "case-row-in-progress";
      case CaseStatus.Closed:
        return "case-row-closed";
    }
  }

  async mounted(): Promise<void> {
    this.headers = [
      {
        text: "",
        value: "serial-number",
        sortable: false,
        width: "1px",
        class: "dense-cell",
        cellClass: "dense-cell"
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        width: "1px",
        class: "dense-cell",
        cellClass: "dense-cell"
      },
      {
        text: "",
        value: "isDG",
        sortable: false,
        divider: true,
        width: "1px",
        class: "dense-cell",
        cellClass: "dense-cell"
      },
      {
        text: "",
        value: "isDirty",
        sortable: false,
        divider: false,
        width: "1px",
        class: "dense-cell",
        cellClass: "dense-cell"
      },
      {
        text: "Case Ref",
        value: "case-id",
        sortable: false,
        divider: true,
        width: "1px",
        cellClass: "case-ref"
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        divider: true,
        width: "140px"
      },
      {
        text: "Assigned To",
        value: "assigned-to",
        sortable: false,
        divider: true,
        width: "120px"
      },
      {
        text: "Created",
        value: "created-at",
        sortable: true,
        divider: true,
        width: "1px"
      },
      {
        text: "Last Screening Date",
        value: "last-screening-date",
        sortable: true,
        divider: true,
        width: "1px"
      }
    ];

    this.detailsColumns = config.displayCaseDetails;
    for (const detail of config.displayCaseDetails) {
      const isLink = detail.type === "link";

      this.headers.push({
        text: isLink ? "" : detail.name,
        value: detail.key,
        sortable: detail.sortable === true,
        class: "case-details",
        cellClass: isLink ? "case-details dense-cell" : "case-details",
        width: isLink ? "1px" : undefined
      });
    }
    this.statusMetadataConfig = await this.ConfigurationService.getStatusMetadataConfig();
    const users = await this.UserService.listUsers({ size: 100 });
    this.users = users._embedded.users;
  }
}
