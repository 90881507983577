






































import {
  Component, Vue, Prop, PropSync,
} from 'vue-property-decorator';

import { FilterField, Filter } from '@/models/filters';

import CaseFilterField from './case-filter-field.vue';

@Component({
  components: {
    CaseFilterField
  }
})
export default class CaseFilter extends Vue {
  @PropSync('filters', { required: true }) syncedFilters!: Filter[];

  @Prop({ required: true }) filterFields!: FilterField[];

  filterTypeChanged(filter: Filter){
    filter.value = "";
  }
}
