














import {
  Component, Vue, Prop
} from 'vue-property-decorator';

import { Filter } from '@/models/filters';

@Component
export default class CaseFilterFieldString extends Vue {
  @Prop({ required: true }) filter!: Filter;
}
