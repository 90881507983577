















import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import { Filter, FilterField } from '@/models/filters';
import CaseFilterFieldDate from './case-filter-field-date.vue';
import CaseFilterFieldEnum from './case-filter-field-enum.vue';
import CaseFilterFieldString from './case-filter-field-string.vue';
import CaseFilterFieldUser from './case-filter-field-user.vue';

@Component
export default class CaseFilterField extends Vue {
  @Prop({ required: true }) filter!: Filter;

  @Prop({ required: true }) filterFields!: FilterField[];

  get field() {
    return this.filterFields.find(x => x.key === this.filter.key);
  }

  get component() {
    switch (this.field?.type) {
      case 'date': return CaseFilterFieldDate;
      case 'enum': return CaseFilterFieldEnum;
      case 'user': return CaseFilterFieldUser;
      default: return CaseFilterFieldString;
    }
  }
}
