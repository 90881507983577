




































import { Component, Vue, Watch, Inject } from "vue-property-decorator";

import Card from "@/components/material/Card.vue";
import CaseTable from "@/components/case-list/case-table.vue";
import { VDataTableOptions, SnackbarOptions } from "@/models/form";
import EcSnackBar from "@/components/common/ec-snackbar.vue";

import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";
import SessionState from "@/store/modules/session-state-module";
import { CaseView } from "@/models/case-maintenance";
import CaseService from "@/services/case-service";
import FilterService from "@/services/filter-service";
import { Filter, FilterField } from "@/models/filters";
import ExportToCsvButton from "@/components/case-list/export-to-csv-button.vue";
import MultiAssignmentControl from "@/components/case-list/multi-assignment-control.vue";
import axios, { CancelTokenSource } from "axios";
import Debounce from "@/helpers/debounce";
import { Dictionary } from "vue-router/types/router";
import ScreeningViewService from "@/services/screening-view-service";
import Config from "@/config";
import { CaseListOptions, Query } from "@/models/session-state-types";
import { PaginationOptions } from "@/models/hal";

const appState = getModule(AppState);
const sessionState = getModule(SessionState);

@Component({
  components: {
    Card,
    CaseTable,
    EcSnackBar,
    ExportToCsvButton,
    MultiAssignmentControl
  }
})
export default class CasesListView extends Vue {
  @Inject() CaseService!: CaseService;
  @Inject() FilterService!: FilterService;
  @Inject() ScreeningViewService!: ScreeningViewService;

  filterFields: FilterField[] = [];
  filters: Filter[] = [];

  assignmentFilter: string | null = null;

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  loading = true;

  items: CaseView[] = [];
  cancelToken?: CancelTokenSource;
  page = 1;
  size = sessionState.defaultPageSize;
  sort: string[] = ["created-at,desc"];
  totalItems = 0;

  statusErrors = {};
  assignmentErrors = {};

  get color() {
    return appState.apiFault ? "error" : "primary";
  }

  get apiFault() {
    return appState.apiFault;
  }

  get sortBy() {
    return this.sort.map(x => x.split(",")[0]);
  }

  get sortDesc() {
    return this.sort.map(x => x.split(",")[1] === "desc");
  }

  async mounted() {
    this.filterFields = await this.FilterService.getFilterDetails();
  }

  @Watch("$route", { immediate: true })
  onRouteChange() {
    const { page = "1", size = "20", sort = ["created-at,desc"], filter, assignment } =
      this.$route.query || {};

    this.page = +page;
    this.size = +size;
    this.sort = [sort as string[]].flat();
    this.assignmentFilter = [assignment].flat()[0];

    const filtersParsed = CasesListView.parseFilterQueryString(filter);
    if (JSON.stringify(this.filters) != JSON.stringify(filtersParsed)) {
      this.filters = filtersParsed;
    }

    const caseListOptions = {
      options: {
        page: this.page,
        size: this.size,
        sort: this.sort
      } as PaginationOptions,
      filter: this.filters,
      assignment: this.assignmentFilter,
      query: this.$route.query as Query
    } as CaseListOptions;
    sessionState.setCaseListOptions(caseListOptions);

    this.loadPage();
  }

  static parseFilterQueryString(query: string | (string | null)[]): Filter[] {
    if (query && typeof query === "string") {
      return query
        .split(",")
        .map(x => x.split("="))
        .map(x => ({ key: x[0], value: x[1] } as Filter))
        .concat([{ key: null, value: "" }]);
    }

    return [{ key: null, value: "" }];
  }

  onFailure(event: any) {
    this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(event);
  }

  async refreshCase(event: { case: CaseView }) {
    const _case = await this.CaseService.readSingle(event.case["case-id"]);

    const index = this.items.findIndex(c => c["case-id"] == event.case["case-id"]);

    this.items.splice(index, 1, _case);
  }

  @Watch("assignmentFilter")
  public assignmentFilterChanged(): void {
    const currentQuery = this.$route.query;
    const newQuery: Dictionary<string | (string | null)[]> = {
      ...currentQuery,
      assignment: this.assignmentFilter || [],
      page: "1"
    };

    if (this.queryHasChanged(newQuery, this.$route.query)) {
      this.$router.replace({ query: newQuery });
    }
  }

  @Watch("filters", { immediate: true, deep: true })
  public insertBlankItem() {
    if (this.filters.length === 0) {
      this.filters.push({ key: null, value: "" });
      return;
    }

    const lastFilter = this.filters[this.filters.length - 1];
    if (lastFilter.key != null || lastFilter.value !== "") {
      this.filters.push({ key: null, value: "" });
    }
  }

  @Watch("filters", { deep: true })
  @Debounce(500)
  public filtersChanged(): void {
    const filterConditions = this.filters.reduce((q, f) => {
      if (f.key) {
        q.push(`${f.key}=${f.value}`);
      }
      return q;
    }, [] as string[]);

    const filter = filterConditions.length ? filterConditions.join(",") : [];

    const currentQuery = this.$route.query;
    const newQuery: Dictionary<string | (string | null)[]> = {
      ...currentQuery,
      filter
    };

    if (this.queryHasChanged(newQuery, this.$route.query)) {
      newQuery.page = "1";
      this.$router.replace({ query: newQuery });
    }
  }

  optionsUpdated(options: VDataTableOptions) {
    const { sortBy, sortDesc, page, itemsPerPage } = options;

    const sort = sortBy.map((by, index) => {
      const search = Config.displayCaseDetails.filter(x => x.key == by);

      if (search.length > 0) {
        return `Details.${by},${sortDesc[index] ? "desc" : "asc"}`;
      }

      return `${by},${sortDesc[index] ? "desc" : "asc"}`;
    });

    const currentQuery = this.$route.query;
    const newQuery: Dictionary<string | (string | null)[]> = {
      ...currentQuery,
      page: `${page}`,
      size: `${itemsPerPage}`,
      sort
    };

    if (this.queryHasChanged(newQuery, this.$route.query)) {
      this.$router.push({ query: newQuery }).catch(() => {
        return true;
      });
    }
  }

  async loadPage() {
    this.loading = true;

    this.statusErrors = {};
    this.assignmentErrors = {};

    const pagination = {
      page: this.page,
      size: this.size,
      sort: this.sort
    };

    try {
      const assignment = this.assignmentFilter || "unassigned";

      if (this.cancelToken) this.cancelToken.cancel();
      this.cancelToken = axios.CancelToken.source();

      const list = await this.CaseService.listCases(
        pagination,
        this.filters,
        assignment,
        this.cancelToken.token
      );

      this.loading = false;
      this.size = list.page.size;
      this.page = list.page.number;
      this.totalItems = list.page.totalElements;
      this.items = list._embedded.cases;

      this.items.forEach(this.setIsDirty);
      this.items.forEach(this.setIsDG);

      sessionState.setCaseListPage(list.page);
      sessionState.setDefaultPageSize(this.size);
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as string);
        this.loading = false;
      }
    }
  }

  async setIsDirty(item: CaseView) {
    const screeningId = item["screen-results"][0]["screening-id"];
    if (!screeningId) {
      Vue.set(item, "isDirty", false);
      return;
    }

    Vue.set(item, "isDirty", this.isDirty(item));
  }

  async setIsDG(item: CaseView) {
    const screeningId = item["screen-results"][0]["screening-id"];
    if (!screeningId) {
      Vue.set(item, "isDG", false);
      return;
    }

    const booking = await this.ScreeningViewService.readScreening(screeningId);
    const isDG = booking["cargo-transport-units"].some((ctu: any) =>
      ctu.cargo.some((cargo: any) => "un-number" in cargo)
    );
    Vue.set(item, "isDG", isDG);
  }

  isDirty(item: CaseView): boolean {
    function buildEvent<T, P extends DatePropertyNames<T>>(type: string, data: T, key: P): any {
      return {
        type,
        data: data as any,
        date: data[key]
      };
    }

    const events = [buildEvent("created", item as CaseView, "created-at")]
      .concat(item["screen-results"].map(x => buildEvent("screening", x, "screening-date")))
      .concat(item["case-notes"].map(x => buildEvent("note", x, "created-at")))
      .concat(item["status-history"].map(x => buildEvent("status", x, "created-at")))
      .concat(item["assignment-history"].map(x => buildEvent("assignment", x, "created-at")))
      .concat(item["emails"].map(x => buildEvent("email", x, "created-at")));

    events.sort((a, b) => (a.date < b.date ? 1 : -1));

    return events[0].type == "screening" || events[0].type == "created";

    type DatePropertyNames<T> = {
      [P in keyof T]: T[P] extends Date ? P : never;
    }[keyof T];
  }

  private queryHasChanged(
    newQuery: Dictionary<string | (string | null)[]>,
    currentQuery: Dictionary<string | (string | null)[]>
  ): boolean {
    const newKeys = Object.keys(newQuery);
    const currentKeys = Object.keys(currentQuery);

    if (newKeys.length !== currentKeys.length) return true;

    for (const key of newKeys) {
      if (!(key in currentQuery)) return true;
      if (this.queryValueHasChanged(newQuery[key], currentQuery[key])) return true;
    }

    return false;
  }

  private queryValueHasChanged(
    newValue: string | (string | null)[],
    currentValue: string | (string | null)[]
  ): boolean {
    if (typeof newValue === "string" && typeof currentValue === "string") {
      return newValue !== currentValue;
    }

    if (typeof currentValue === "string") {
      currentValue = [currentValue];
    }

    return JSON.stringify(newValue) !== JSON.stringify(currentValue);
  }
}
