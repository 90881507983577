


















import {
  Component, Vue, Prop
} from 'vue-property-decorator';

import { Filter, FilterField } from '@/models/filters';

@Component
export default class CaseFilterFieldEnum extends Vue {
  @Prop({ required: true }) filter!: Filter;
  @Prop({ required: true }) field!: FilterField;
}
