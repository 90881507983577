






import { Component, PropSync, Vue } from "vue-property-decorator";

@Component
export default class AssignmentFilter extends Vue {
  @PropSync('value', { required: true }) syncedValue!: string | null;

  tabs = [
    { text: 'Unassigned', value: 'unassigned' },
    { text: 'My Cases', value: 'mine' },
    { text: 'All', value: 'all' },
  ];

  get tab(): number {
    if (this.syncedValue === null) return 0;

    return this.tabs.findIndex(x => x.value === this.syncedValue)
  }

  tabChanged(index: number): void {
    const tab = this.tabs[index];

    if (!this.syncedValue && index === 0) return;

    this.syncedValue = tab.value;
  }
}
