var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('status-config-dialog',{attrs:{"dialog":_vm.showStatusConfigDialog,"myConfigTemplate":_vm.getConfigTemplate(_vm.newCaseStatusId)},on:{"update:dialog":function($event){_vm.showStatusConfigDialog=$event},"update-case-status":_vm.onDialogUpdate,"cancel":_vm.onDialogCancel}}),_c('case-filter',{attrs:{"filters":_vm.syncedFilters,"filter-fields":_vm.filterFields},on:{"update:filters":function($event){_vm.syncedFilters=$event}}}),_c('assignment-filter',{attrs:{"value":_vm.syncedAssignmentFilter},on:{"update:value":function($event){_vm.syncedAssignmentFilter=$event}}}),_c('v-data-table',_vm._g(_vm._b({key:_vm.dataTableKey,staticClass:"case-table elevation-2 mt-2",attrs:{"headers":_vm.headers,"dense":"","item-key":"case-id","items":_vm.items,"item-class":_vm.getRowClass,"page":_vm.syncedPage,"items-per-page":_vm.syncedItemsPerPage,"server-items-length":_vm.serverItemsLength,"hide-default-footer":"","loading":_vm.loading,"show-expand":"","sort-by":"created-at"},on:{"click:row":function($event){return _vm.openCase($event)}},scopedSlots:_vm._u([{key:"item.isDG",fn:function(ref){
var item = ref.item;
return [(item.isDG)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-rhombus-outline")])]}}],null,true)},[_vm._v(" Declared DG ")]):_vm._e()]}},{key:"item.isDirty",fn:function(ref){
var item = ref.item;
return [(item.isDirty)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.isDirty)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-blank-circle")]):_vm._e()]}}],null,true)},[_vm._v(" New Screening ")]):_vm._e()]}},{key:"item.serial-number",fn:function(ref){
var index = ref.index;
return [_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.rowNumber(index)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('status-control',{staticClass:"embedded-select",attrs:{"value":item.status,"state":_vm.statusUpdate(item),"error-message":_vm.statusError(item),"dense":""},on:{"input":function($event){return _vm.onDropdownStatusChange(item, $event)}}})],1)]}},{key:"item.assigned-to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('assignment-control',{staticClass:"embedded-select",attrs:{"caseId":item.caseId,"selectedUserId":item['assigned-to'],"state":_vm.assignmentUpdateStateFor(item),"users":_vm.users,"error-message":_vm.assignmentErrorFor(item),"dense":""},on:{"input":function($event){return _vm.updateAssignment(item, $event)}}})],1)]}},{key:"item.created-at",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("ago")(value)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("datetime")(value)))])])]}},{key:"item.last-screening-date",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("datetime")(value)))])]}},_vm._l((_vm.detailsColumns),function(col,index){return {key:("item." + (col.key)),fn:function(ref){
var item = ref.item;
return [_c('case-detail',{key:index,attrs:{"case":item,"detail":col}})]}}}),{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('case-table-expand',{attrs:{"model":item}})],1)]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners),[_c('template',{slot:"header.serial-number"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"refresh-button",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('reload')}}},on),[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"16","width":"2"}}):_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_vm._v(" Refresh ")])],1),_c('template',{slot:"loading"},[_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@3"}})],1),(_vm.apiFault)?_c('template',{slot:"no-data"},[_vm._v(" Unable to fetch results from the API ")]):_vm._e()],2),_c('v-row',[_c('v-col',[(_vm.numberOfPages > 1)?_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":"10"},model:{value:(_vm.syncedPage),callback:function ($$v) {_vm.syncedPage=$$v},expression:"syncedPage"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Showing cases "+_vm._s(_vm.rowNumber(0))+"-"+_vm._s(_vm.rowNumber(_vm.items.length - 1))+" of "+_vm._s(_vm.serverItemsLength)+" "),_c('v-select',{attrs:{"id":"itemsPerPage","label":"Cases per page:","items":[10, 20, 50],"hide-details":"","dense":"","filled":""},model:{value:(_vm.syncedItemsPerPage),callback:function ($$v) {_vm.syncedItemsPerPage=$$v},expression:"syncedItemsPerPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }